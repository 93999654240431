<template>
  <LayoutParametrage>
    <template #current_page>
      <span>{{ title }}</span>
      <span class="font-semibold">{{ secondTitle }}</span>
    </template>
    <template #add_button>
      <router-link
        class="btn-green px-6 py-2 sm:w-full md:mt-8 mr-4"
        to="/parametrage/bilan-financier/list"
      >
        Retour à la liste
      </router-link>
    </template>
    <template #modal>
      <ValidationObserver v-slot="{ invalid }" ref="validationObserver">
        <div class="flex gap-4 items-center">
          <div class="w-1/3 md:w-2/3 sm:w-full">
            <pro-input
              name="name"
              requirements="required"
              v-model="template.name"
              label="Nom"
            />
          </div>
          <div class="w-1/3 md:w-2/3 sm:w-full mt-4">
            <div class="w-full p-3 flex items-center">
              <Checkbox
                id="default"
                :val="template.default"
                v-model="template.default"
              />
              <span
                @click="template.default = !template.default"
                class="font-normal text-base text-gray-650 font-arial cursor-pointer"
              >
                Bilan financier par défaut ?
              </span>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="flex justify-between items-center border-b-2 pb-2">
        <div class="text-xl font-semibold">Champs :</div>
        <button
          @click="toggleAddTopicModal"
          class="font-semibold text-white bg-promy-green-300 hover:bg-promy-green-400 py-2 px-4 rounded-lg text-lg"
        >
          Ajouter Champ
        </button>
      </div>
      <TopicSheet
        v-if="template.recapTopic"
        :key="0 + template.recapTopic.label"
        :topic="template.recapTopic"
        :topicIndex="0"
        :isReadOnly="false"
        :ref="'sheet' + template.recapTopic.label"
      />
      <draggable
        class="flex flex-col m-4 list-group"
        :key="refreshToken"
        tag="div"
        v-model="template.draggableTopics"
        @end="dragEnd"
        @start="drag = true"
        handle=".handle"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <TopicSheet
            v-for="(topic, idx) in template.draggableTopics"
            @dataChange="topicDataChange"
            @cellLockChange="topicCellLockChange"
            :key="idx + topic.label"
            :topic="topic"
            :topicIndex="idx"
            @deleteTopic="deleteTopic"
            :isReadOnly="false"
            :ref="'sheet' + topic.label"
          />
        </transition-group>
      </draggable>
      <AddTopicModal
        ref="addTopicModalRef"
        @addTopic="addTopic"
      ></AddTopicModal>
      <div class="flex flex-col m-4">
        <button
          class="btn-green px-6 py-2 w-40 sm:w-full md:mt-8 mr-4"
          @click="save()"
        >
          Sauvegarder
        </button>
      </div>
    </template>
  </LayoutParametrage>
</template>

<script>
import TopicSheet from '../../../../components/BilanFinancier/TopicSheet.vue'
import AddTopicModal from '../../../../components/BilanFinancier/AddTopicModal.vue'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import bilan from '@/mixins/jspreadsheet/bilan.mixin'

export default {
  components: {
    TopicSheet,
    draggable,
  },
  mixins: [bilan],
  data() {
    return {
      refreshToken: 0,
      template: {
        name: '',
        default: false,
        topics: [],
        draggableTopics: [],
        recapTopic: null,
      },
      drag: false,
    }
  },
  mounted() {
    if (this.$route.name == 'bilan-templates-add') {
      this.$store.dispatch(
        'bilanFinancier/getLatestTemplateForType',
        this.$route.params.typeId,
      )
    } else if (this.$route.name == 'bilan-templates-edit') {
      if (!this.selectedTemplate.id) {
        this.$store.dispatch(
          'bilanFinancier/getTemplateById',
          this.$route.params.id,
        )
      } else {
        this.syncTemplate()
      }
    }
  },
  methods: {
    dragEnd() {
      this.drag = false
      this.refreshToken++
    },
    toggleAddTopicModal() {
      this.$refs.addTopicModalRef.toggleAddTopicModal()
    },
    addTopic(topic) {
      this.template.draggableTopics.push(topic)
    },
    topicDataChange({ index, data }) {
      this.template.draggableTopics[index].template.data = data
    },
    topicCellLockChange() {
      this.template.draggableTopics.forEach((t) => {
        const sheetRef = this.$refs['sheet' + t.label][0]
        sheetRef.isLocked = sheetRef.getIsLocked(sheetRef.jExcelObjects[0])
      })
    },
    save() {
      try {
        this.template.topics = [
          this.template.recapTopic,
          ...this.template.draggableTopics,
        ]
        const topics_saved = this.template.topics.map((topic, i) => {
          const sheetRef = Array.isArray(this.$refs['sheet' + topic.label])
            ? this.$refs['sheet' + topic.label][0]
            : this.$refs['sheet' + topic.label]
          return {
            ...sheetRef.topicToSave(),
            ...(topic.id && this.isEditingForm && { id: topic.id }),
            order: i,
          }
        })

        const templateToSave = {
          ...(this.$route.params.id &&
            this.isEditingForm && {
              id: this.$route.params.id,
            }),
          name: this.template.name,
          default: this.template.default,
          type: !this.isEditingForm
            ? this.$route.params.typeId
            : this.selectedTemplate.type.id,
          topics: topics_saved,
        }

        const action = !this.isEditingForm
          ? 'bilanFinancier/saveTemplate'
          : 'bilanFinancier/updateTemplate'

        this.$store.dispatch(action, templateToSave)

        this.$toaster.success('Template est bien été enregistré')
      } catch (e) {
        console.log(e)
        this.$toaster.warning("Problem de l'enregistrement!")
      }
    },
    deleteTopic(index) {
      this.template.draggableTopics.splice(index, 1)
      this.refreshToken++
    },
    syncTemplate() {
      this.template.topics = this.selectedTemplate.topics
      this.template.draggableTopics = this.selectedTemplate.topics.filter(
        (t, i) => i !== 0,
      )
      this.template.recapTopic = this.selectedTemplate.topics[0]
      this.template.default = this.selectedTemplate.default
      this.template.name = this.selectedTemplate.name
    },
  },
  computed: {
    ...mapGetters({
      selectedTemplate: 'bilanFinancier/getSelectedTemplate',
    }),
    isEditingForm() {
      return this.$route.name == 'bilan-templates-edit'
    },
    title() {
      return this.isEditingForm
        ? 'Modifier La template : '
        : 'Ajouter une template pour type : '
    },
    secondTitle() {
      return this.isEditingForm
        ? this.template.name
        : this.selectedTemplate.type
        ? this.selectedTemplate.type.name
        : ''
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  watch: {
    selectedTemplate: {
      handler: function () {
        this.syncTemplate()
      },
    },
  },
}
</script>
<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
